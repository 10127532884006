/**
 * This class contains common functions for feed and production items.
 */

import {app} from 'app'
import store from 'store'
import Entity from 'components/entity/Entity'
import _ from 'lodash'
import productionTypes from 'components/production/productionTypes'

class Item {

    /**
     * Parse an item and add some extra fields.
     * @return void This doesn't return anything, but "item" is changed by reference.
     */
    static parse(item, type = '', now = 0, props = {}) {

        const context = this
        item = context.parsingFix(item) // parsing fix WN

        // The item doesn't need to be parsed.
        if (item.status && item.startTime && item.endTime && (type && type !== 'event')) {
            return
        }

        if (!type && item.elementType === 'event') {
            type = 'event'
        }

        if (now == 0) now = store.state.app.now

        // Set the element type, through its "Start" date field.
        if (!type) {
            let field = Object.keys(item).find(v => v.indexOf('Start') > -1)

            if (field) {
                let i = field.indexOf('Start')
                type = field.substring(0, i).toLowerCase()
                if (type === 'mcrcomment') type = 'mcrComment'
            }
        }

        if (!item.elementType) {
            Vue.set(item, 'elementType', type)          //JW type is blank ?
        }

        if (type === 'event') {
            if (!item['event_start']) Vue.set(item, 'postponed', true);
            let _eventStartDate = item['event_date'] + ' ' + item['event_start']
            let _eventDateToLocal = moment(moment.utc(_eventStartDate).toDate()).format('YYYY-MM-DD HH:mm:ss');
            Vue.set(item, 'eventStart', _eventDateToLocal)
            Vue.set(item, 'eventEnd', _eventDateToLocal)
        }

        if(!item.elementTypeDetailed) {
            if (item.elementType === 'event') {
                Vue.set(item, 'elementTypeDetailed', type)
            }
            if (item.elementType === 'feed') {
                Vue.set(item, 'elementTypeDetailed', type)
            }
            if (item.elementType === 'production' && item.productionType) {
                Vue.set(item, 'elementTypeDetailed', item.productionType)
            }
            else if (item.elementType !== 'feed' && item.elementType !== 'event' && !item.productionType) {
                Vue.set(item, 'elementTypeDetailed', 'production')
            }
        }


        // There was UNIX TimeStamp issue for 2020+ strange, had to fix it
        // by re-momenting times. (WN)
        // Start value.
        let startField = item[`${type}Start`] ? `${type}Start` : 'start'
        Vue.set(item, 'startTime', moment(item[startField]).unix())

        // End value.
        let endField = item[`${type}End`] ? `${type}End` : 'end'
        Vue.set(item, 'endTime', moment(item[endField]).unix())

        // Set item status.
        let status = !props.hasOwnProperty('status') ? context.getStatus(item, now) : props.status
        Vue.set(item, 'status', status)

    }

    static parsingFix(item) {
        if (typeof item.id === 'undefined') {
            if (item.associationId === 'undefined') {
                try {
                    item = JSON.parse(item)
                } catch {
                    console.log(item, "item can not be parsed", (item && item.associationId ? item.associationId : item))
                }
            } else {
                item.title = "PLEASE DELETE THIS, BOOKING NOT FOUND OR DELETED"
                item.elementType = 'Unknown'
                item.elementTypeDetailed = 'Unknown'
            }
        }
        return item;
    }

    /**
     * Get the status for a feed/production, which can be used to set the
     * right color in the list/timeline.
     * @param object item The item to retrieve status for.
     * @param integer now (optional) The timestamp for "now".
     * @return string
     */
    static getStatus(item, now = 0) {
        if (!item.signoffs) return app.$store.state.config.ITEM_STATUS.PASSED
        if (now == 0) now = store.state.app.now
        //console.log(item);
        // The "safe interval" for an item is 24 hours (86400 seconds).
        const interval = now + 86400

        // Check if all signoffs in the item are signed.
        let signed = item.signoffs.every(v => v.signed)

        // For cars, we can assume the item is always signed.
        if (item.elementType === 'production' && item.elementTypeDetailed === 'car') {
            signed = true
        }

        switch (true) {
            case (now > item.endTime):
                return app.$store.state.config.ITEM_STATUS.PASSED
            case (!signed && item.startTime < interval):
                return app.$store.state.config.ITEM_STATUS.UNSIGNED_PRIORITY
            case (!signed && item.startTime >= interval):
                return app.$store.state.config.ITEM_STATUS.UNSIGNED
            default:
                return app.$store.state.config.ITEM_STATUS.SIGNED
        }
    }

    /**
     * Check if a passed item is filtered according to the passed filters.
     * @return boolean
     * @param filters
     * @param item
     * @param entities
     * @param forMcr
     * @param hideFin
     */
    static isFiltered(filters, item, entities = {}, forMcr = false, hideFin = false) {
        // If no filter is provided, just show everything.
        if (Object.keys(filters).length === 0) {
            return true
        }

        // Get all active filters, e.g. all filter items with selected = true.
        let activeFilters = Lazy(filters).filter((v, k) => {
            if (k === 'advanced') {
                return false
            }
            if (Array.isArray(v)) {
                return v.length > 0
            }
            else {
                return v
            }
        }).toObject()

        // If no selected filter items was found, just show everything.
        if (Object.keys(activeFilters).length === 0) {
            return true
        }

        let visible = forMcr ? this.matchMcrFilter(activeFilters, item, entities, hideFin) : this.matchFilter(activeFilters, item, entities)

        // Apply advanced filters, if available.
        if (visible && filters.hasOwnProperty('advanced')) {

            // We need to check if any advanced filter is selected, to avoid
            // calling the advanced filter method if it's not necessary.
            let noFilter = Lazy(filters.advanced)
                .every(v => {
                    return Lazy(v).every(v2 => {
                        return Lazy(v2).every(v3 => {
                            return Lazy(v3).every(v4 => v4.length === 0)
                        })
                    })
                })

            if (!noFilter) {
                Lazy(filters.advanced)
                    .each((v, k) => {
                        switch (k) {
                            case 'combine':
                                visible = this.isFilteredCombine(v, item, entities)
                                break
                        }
                    })
            }

        }

        return visible
    }

    /**
     * Check if the passed "item" matches the passed filters set.
     */
    static matchFilter(filters, item, entities = {}) {

        let visible = null

        // added required entities, should be fine to use this-way, instead of using equipment
        const oldItem = item
        if (app.$route.name === 'equipment.usage.view') {
            item = item.usageItem
        }

        if (typeof item.elementType === 'undefined') Item.parse(item)

        /**
         * Loop through all active filters (without "advanced") and check for
         * visibility. "Advanced filters" will be treated later.
         */
        Lazy(filters)
            .each((v, k) => {

                // If the item visibility is already set to "false", it means that
                // we don't need to check it once again, against another filter.
                if (visible === false) return

                let field = k
                // Special condition for "country" (MCR) on feeds. Removing feeds from country search
                if ( (item.elementType == 'feed') && ((field == 'country'))) {
                    field = 'mcr'
                }
                //VR -
/*
                //1. Add special variable for associations
                //2. If association and contribution's parent relies to conditions
                //3. There are more than one association could be, so we need to make array of all associations and contributions
                let elementTypes = [];
                //Check for contributions or associations
                if (item.associations){
                    //check here for relies to conditions items
                    for (var a of item.associations){
                        if (a.elementType){
                            elementTypes.push({
                                elementType: a.elementType,
                                type: "associations"
                            });
                        }
                    }
                }

                if (item.contributions){
                    //check here for relies to conditions items
                    for (var a of item.contributions){
                        if (a.elementType){
                            elementTypes.push({
                                elementType: a.elementType,
                                type: "contributions"
                            });
                        }
                    }
                }

                let isProdAssocExists = elementTypes.length > 0 && elementTypes.some(f => {return f.elementType == "production"});
                let isFeedAssocExists = elementTypes.length > 0 && elementTypes.some(f => {return f.elementType == "feed"});
                let isContAssocExists = elementTypes.length > 0 && elementTypes.some(f => {return f.elementType == "contribution"}); */
                let _uCount = ''

                switch (true) {
                    case field === 'equipmentType':
                        let _uCountry = app.$data.user.get.country
                        visible = false

                        if (app.$route.name !== 'equipment.usage.view') {
                            if (item.equipmentType) {
                                visible = _.includes(v, item.equipmentType)
                            } else {
                                if (item.equipment) {
                                    Lazy(item.equipment).each(v1 => {
                                        //if (_.includes(v, v1.type) && v1.country === _uCountry || _.includes(v, v1.type) && v1.notCountryRestricted) visible = true
                                        if (_.includes(v, v1.type) && _.includes(v1.destination, _uCountry)) visible = true
                                    })
                                }
                            }
                        } else {
                            if (oldItem.equipment) {
                                if (_.includes(v, oldItem.equipment.type)) {
                                    if (filters.country) {
                                     //visible = _.includes(filters.country, oldItem.country) || _.includes(v, oldItem.equipment.type) && oldItem.equipment.notCountryRestricted
                                     visible =  (_.intersection(filters.country, oldItem.equipment.destination)).length > 0
                                    } else {
                                        visible = true
                                    }

                                }
                            }
                        }
                        break

                    case field === 'ingestions':
                        visible = false
                        // record / notrecord
                        let countries

                        if (item.elementType === 'feed'){
                            countries = item.mcr
                            let _ingest = this.ingestionValues(item.houseNumber, countries, entities.ingestions)
                            visible = _.includes(v, _ingest);
                            //console.log(item, v, k, 'item v k field', field, _ingest, _.includes(v, _ingest))
                        }
                        if (item.elementType === 'production'){
                            countries = entities.channel.getItem(item.channel)
                            countries = countries.country
                            let _ingest = this.ingestionValues(item.houseNumber, countries, entities.ingestions)
                            visible = _.includes(v, _ingest);
                        }
                        if (item.elementType === 'contribution') {
                            let _ingest = this.ingestionValues(item.houseNumber, item.country, entities.ingestions)
                            visible = _.includes(v, _ingest);
                        }
                        // finished filter
                        if (_.includes(v, 'finished')) {
                            visible = false
                            let _mcrStatus = item.mcrStatus ? item.mcrStatus : (item.mcrstatus ? item.mcrstatus : null)
                            Lazy(_mcrStatus).each((v1,k1) => {
                                // country also should be the same darling.
                                visible = v1.status === 4 && v1.country === app.$data.user.get.country;
                            })
                        }
                        break

                    // Special condition for "sport" that isn't present inside an item.
                    case (!item.hasOwnProperty(k) && field === 'sport' && item.league && entities.hasOwnProperty('league') && entities.hasOwnProperty('sport')):
                        const league = entities.league.getItem(item.league)
                        const sport = entities.sport.getItem(league.reference)
                        visible = (!league && v === '_empty_') || (v.indexOf(sport.id) > -1)
                        break
                    //case (((['contribution'].indexOf(item.elementType) > -1) || isContAssocExists) && (field == 'country')):
                    case (((['contribution'].indexOf(item.elementType) > -1)) && (field === 'country')):
                        visible = v.indexOf(item.country) > -1;
                        /*
                        if(isContAssocExists){
                            if (item.contributions && !visible){
                                visible = item.contributions.some(f => {
                                    return v.indexOf(f.country) > -1;
                                });
                            }
                            if (item.associations && !visible){
                                visible = item.associations.some(f => {
                                    return v.indexOf(f.country) > -1;
                                });
                            }
                        }*/
                        break
                    //case ( (['contribution'].indexOf(item.elementType) > -1 || isContAssocExists) && (field == 'contribution') ):
                    case ( (['contribution'].indexOf(item.elementType) > -1) && (field == 'contribution') ):
                        visible = v.indexOf(item.info) > -1;
                        /*
                        if(isContAssocExists){
                            if (item.contributions && !visible){
                                visible = item.contributions.some(f => {
                                    return v.indexOf(f.info) > -1;
                                });
                            }
                            if (item.associations && !visible){
                                visible = item.associations.some(f => {
                                    return v.indexOf(f.info) > -1;
                                });
                            }
                        }*/
                        break
                    // Special condition for "country" on productions. (Channel of Interest logic implemented also)
                    case ( (['production'].indexOf(item.elementType) > -1) && (field === 'country') ):
                        _uCount = app.$data.user.get.country
                        //Here we suppose eng, edit and car should be filtered by equipment type country
                        if (item.productionType === "eng" || item.productionType === "edit" || item.productionType === "car" || item.runType === 89 || item.runType === 90){
                            //let equipments = item.equipment.filter(t => _.includes(v,t.country));
                            let equipments = item.equipment.filter(t => {
                                let interEq = _.intersection(v,t.destination)
                                return interEq && interEq.length > 0
                            });
                            visible = !!(equipments && equipments.length > 0)
                        }else{
                            const channel = entities.channel.getItem(item.channel)
                            // channel description for the channel ownership wN
                            if (typeof channel === 'undefined' || !channel) {
                                console.error("CHANNEL NOT FOUND! item -> ", item)
                                visible = true
                                return true;
                            }
                            let _interest
                            if (channel.description) _interest = channel.description.split(',')

                            // equipment usage view, doesnt care about channel interest.
                            if (app.$route.name === 'equipment.usage.view') {
                                if (oldItem.equipment) {
                                    // if (oldItem.equipment.country === _uCount || oldItem.equipment.notCountryRestricted) {
                                    //     visible = true
                                    // }
                                    let eqUsageInter = _.intersection(v,oldItem.equipment.destination)
                                    if (eqUsageInter && eqUsageInter.length > 0/* _.includes(oldItem.equipment.destination, _uCount )*/) {
                                        visible = true
                                    }
                                }
                            } else {
                                let equipmentCount = item.equipment.filter(t => {
                                    let intersec = _.intersection(v,t.destination)
                                    return intersec.length > 0
                                }).length;
                                visible = (!item.channel && v === '_empty_') || (!!(channel && v.indexOf(channel.country) > -1)) || _.includes(_interest, _uCount) || equipmentCount > 0
                            }
                        }
                        break
                    case (['feed'].indexOf(item.elementType) > -1) && (field === 'mcr') :
                        _uCount = app.$data.user.get.country
                        if (app.$route.name === 'equipment.usage.view') {
                            if (oldItem.equipment) {
                                // if (oldItem.equipment.country === _uCount || oldItem.equipment.notCountryRestricted) {
                                //     visible = true
                                // }
                                let eqUsageInterec = _.intersection(v,oldItem.equipment.destination)
                                if (eqUsageInterec && eqUsageInterec.length > 0/* _.includes(oldItem.equipment.destination, _uCount) */) {
                                    visible = true
                                }
                            }
                        } // fixing for other pages
                        else {
                            visible =  _.intersection(v, item.mcr).length > 0
                        }
                        break
                    //case ( (['production', 'feed'].indexOf(item.elementType) > -1 || (isProdAssocExists || isFeedAssocExists)) && (field == 'contribution') ):
                    case ( (['production', 'feed'].indexOf(item.elementType) > -1) && (field == 'contribution') ):
                        visible = true;
                        break
                    /*case ( (['production', 'feed'].indexOf(item.elementType) > -1) && (field == 'elementType') ):
                        console.log(v);
                        if (v.indexOf("contribution") > -1)
                            visible = item.contributions && item.contributions.length > 0;
                        else
                            visible = item.elementType =;

                        break*/
                    //case ( (['contribution', 'feed'].indexOf(item.elementType) > -1 || (isContAssocExists || isFeedAssocExists)) && (field == 'channel') ):
                    case ( (['contribution', 'feed'].indexOf(item.elementType) > -1) && (field == 'channel') ):
                        visible = true;
                        break
                        /*
                    case ((['production', 'feed'].indexOf(item.elementType) == -1) && field == 'contribution'):{
                            visible = item.info && v.indexOf(item.info) > -1;
                        }
                        break*/
                    case (Array.isArray(item[field])):
                        //TODO: check for associations and contributions here

                        // The filter value is an array and so the item value,
                        // then we need to find any of the filter values inside
                        // the item value array.
                        if (Array.isArray(v)) {
                            visible = v.findIndex(z => item[field].indexOf(z) > -1) > -1
/*
                            if (item.contributions && !visible){
                                visible = item.contributions.some(f => {
                                    return v.findIndex(z => f[field] ? f[field].indexOf(z) > -1 : false) > -1
                                });
                            }

                            if (item.associations && !visible){
                                visible = item.associations.some(f => {
                                    return v.findIndex(z => f[field] ? f[field].indexOf(z) > -1 : false) > -1
                                });
                            }*/
                        }

                        // The filter value isn't an array.
                        else {
                            visible = item[field].indexOf(v) > -1
                            /*
                            if (item.contributions && !visible){
                                visible = item.contributions.some(f => {
                                    return f[field].indexOf(v) > -1
                                });
                            }

                            if (item.associations && !visible){
                                visible = item.associations.some(f => {
                                    return f[field].indexOf(v) > -1
                                });
                            }*/
                        }


                        break
                    case (typeof v === 'object' && !Array.isArray(v)):
                        // free text search
                        switch (v.type) {
                            case 'text':
                                visible = !!Lazy(item)
                                    .filter((itemValue, itemField) => {
                                        return typeof itemValue === 'string'
                                        || itemField.toLowerCase() === 'id'
                                        || itemField.toLowerCase() === 'crew'
                                    })
                                    .find((itemValue, itemField) => {
                                        if (itemField.toLowerCase() === 'id'){
                                            itemValue = itemValue + '';
                                            return itemValue.toLowerCase().indexOf(v.value.toLowerCase()) > -1
                                        }else if (itemField.toLowerCase() === 'crew'){
                                            if (Array.isArray(itemValue) && itemValue.length > 0){
                                                let crew = itemValue.find(t => {
                                                    if (t.user && t.user.name){
                                                        return t.user.name.toLowerCase().indexOf(v.value.toLowerCase()) > -1
                                                    }
                                                })
                                                return crew;
                                            }
                                        }else{
                                            return itemValue.toLowerCase().indexOf(v.value.toLowerCase()) > -1
                                        }

                                    })

                                break
                        }
                        break


                    // The item value is not an array.
                    default:

                        // The filter value is an array, then we try to find the
                        // item value (item[k]) inside that array.
                        if (Array.isArray(v)) {
                            visible = (!item.hasOwnProperty(field) && v == '_empty_') || v.indexOf(item[field]) > -1 ? true : false
/*
                            if (item.contributions && !visible){
                                visible = item.contributions.some(f => {
                                    return (!f.hasOwnProperty(field) && v == '_empty_') || v.indexOf(f[field]) > -1 ? true : false
                                });
                            }
                            if (item.associations && !visible){
                                visible = item.associations.some(f => {
                                    return (!f.hasOwnProperty(field) && v == '_empty_') || v.indexOf(f[field]) > -1 ? true : false
                                });
                            }*/
                        }

                        // A simple comparison can be done between the values.
                        else {
                            visible = (!item.hasOwnProperty(field) && v == '_empty_') || (item[field] == v) ? true : false
/*
                            if (item.contributions && !visible){
                                visible = item.contributions.some(f => {
                                    return (!item.hasOwnProperty(field) && v == '_empty_') || (item[field] == v) ? true : false
                                });
                            }
                            if (item.associations && !visible){
                                visible = item.associations.some(f => {
                                    return (!item.hasOwnProperty(field) && v == '_empty_') || (item[field] == v) ? true : false
                                });
                            }*/
                        }

                        break
                }

            })
        //console.log('whats the last', visible)
        return visible

    }

    /**
     * Check if the passed "item" is filtered by a "combine" advanced filter.
     */
    static isFilteredCombine(filters, item, entities = {}) {
        const output = {}
        Lazy(filters)
            .each((advancedFilterItem, filterName) => {
                if (!output.hasOwnProperty(filterName)) {
                    output[filterName] = {}
                }
                Lazy(advancedFilterItem)
                    .filter(elements => Object.keys(elements).length > 0)
                    .each((elements, advancedFilterName) => {
                        if (!output[filterName].hasOwnProperty(advancedFilterName)) {
                            output[filterName][advancedFilterName] = {}
                        }
                        Lazy(elements)
                            .filter(items => items.length > 0)
                            .each((items, fieldId) => {
                                const filter = {}
                                filter[filterName] = fieldId
                                const filter2 = {}
                                filter2[advancedFilterName] = items
                                output[filterName][advancedFilterName][fieldId] = this.matchFilter(filter, item, entities) && this.matchFilter(filter2, item, entities)
                            })
                            
                    })
            })
        // The first level is a "filter OR filter" combination.
        // Note that we don't use LazyJS here because we get undefined key on the
        // first interation (for unknown reason) when using Lazy(output).some()
        
        let countries = Object.keys(output.country.elementType);

        let visible = Object.keys(output)
            .filter(k => Object.keys(output[k]).length > 0)
            .some(k => {
                //console.log(output);
                //console.log(item.elementType + " " + item.id);
                // The second level is a "filter AND filter" combination.
                let filter = Object.keys(output[k])
                    .filter(k2 => {
                        return Object.keys(output[k][k2]).length > 0
                    })
                
                    //k2 - filter name - every
                    //TODO: remove every.
                    //First we select type
                    //Then we filter in this type
                    
                    return filter.every(k2 => {

                        // @TODO This "AND" section needs more work, because you
                        // can have a filter with "Denmark = Productions" AND
                        // "Denmark = Berlin Marathon", which means "only danish
                        // productions with league Berlin Marathon", but this is
                        // not working properly for now...

                        // The third level is a "filter OR filter" combination.

                        //Should be compared with element type
                        //Enumerates all keys
                        //If one of the keys is not true, then returns false
                        //AND condition
                        return Object.keys(output[k][k2])
                            .some(k3 => {
                                //Here we add the Country condition for the related filters
                                //We check, if item's country is equal filter's value
                                if (k2 === "channel"){
                                    const channel = entities.channel.getItem(item.channel);
                                    return countries.every(e => {
                                        if (channel && channel.country === e && Object.keys(output[k][k2]).indexOf(e) > -1){
                                            return output[k][k2][k3] === true
                                        }else{
                                            return true;
                                        }
                                    });
                                }else if(k2 === "contribution"){
                                    return countries.every(e => {
                                        if (item.country && item.country === e && Object.keys(output[k][k2]).indexOf(e) > -1){
                                            return output[k][k2][k3] === true
                                        }else{
                                            return true;
                                        }
                                    });
                                }
                                return output[k][k2][k3] === true
                            })

                    })

            })
        
            return visible

    }
    /**
     * Sending Ingestor Entity to BookIngestion component to adjust traffic buttons
     * by their entities.
     */
    static ingestionValues(houseNumber, country, entity) {
        const userCountry = app.$data.user.get.country
        const data = entity
        if (!data || !data.get.items || data.get.items.length === 0) return;

        // Process houseNumber
        let processedHouseNumber = houseNumber && isJsonSring(houseNumber)
            ? JSON.parse(houseNumber)[userCountry]?.toLowerCase() ?? null
            : houseNumber?.toLowerCase();

        const oneLetterOrOnlyIntegers = /^[a-zA-Z]?\d+$/;
        let value

        for (const v of data.get.items) {
            if (!v.active) continue;

            const label = v.label?.toLowerCase();
            const shortLabel = v.shortlabel?.toLowerCase();
            const userField2 = v.userfield2_value?.toLowerCase();

            // integrations: NULL, Blank, Empty, Integer or specific labels
            const isMatch = (
                (processedHouseNumber && (label === processedHouseNumber || shortLabel === processedHouseNumber || userField2 === processedHouseNumber)) ||
                (processedHouseNumber === null && ['null', 'blank', 'empty'].includes(label)) ||
                (processedHouseNumber === '' && ['null', 'blank', 'empty'].includes(label)) ||
                (oneLetterOrOnlyIntegers.test(processedHouseNumber) && label === 'int')
            ) && ((v.country === userCountry && !v.default) || v.default);

            if (isMatch) {
                value = v.userfield1_value;
                break;
            }
        }

        if (value) return value;
    }

    /**
     * Check if the passed "item" matches the passed filters set.
     */
    static matchMcrFilter(filters, item, entities = {}, hideFin) {

        let visible = null

        if (typeof item.elementTypeDetailed === 'undefined') Item.parse(item)

        if (!hideFin) filters.hideFinished = false

        let defaultCountries = store.state.system.countries ? store.state.system.countries.map(i => i.code) : ['dk','fi','no','se','uk']


        /**
         * Loop through all active filters (without "advanced") and check for
         * visibility. "Advanced filters" will be treated later.
         */
        Lazy(filters)
            .each((v, k) => {
                // If the item visibility is already set to "false", it means that
                // we don't need to check it once again, against another filter.
                if (visible === false) return

                let field = k

                if (item.elementType === 'mcrComment') {
                    let country = item.country
                    if (!Array.isArray(item.country) && item.country.indexOf(',') > -1) {
                        country = item.country.split(',')
                        item.country = country
                    }
                    if (!Array.isArray(item.country) && item.country.indexOf(',') < 0) {
                        item.country = [country]
                    }

                }

                // Special condition for "country" (MCR) on feeds. Removing feeds from country search
                if ( (item.elementTypeDetailed == 'feed') && ((field == 'country'))) {
                    field = 'mcr'
                }
                
                let _uCount = ''

                switch (true) {
                    case field === 'hideFinished':
                        visible = v;
                        break
                    case field === 'equipmentType':
                        let _uCountry = app.$data.user.get.country
                        visible = false

                        if (item.equipmentType) {
                            visible = _.includes(v, item.equipmentType)
                        } else {
                            if (item.equipment) {
                                Lazy(item.equipment).each(v1 => {
                                    //if (_.includes(v, v1.type) && v1.country === _uCountry || _.includes(v, v1.type) && v1.notCountryRestricted) visible = true
                                    if (_.includes(v, v1.type) && _.includes(v1.destination, _uCountry)) visible = true
                                })
                            }
                        }
                        
                        break
                    case field === 'ingestions':
                        visible = false
                        // record / notrecord
                        let countries

                        if (item.elementTypeDetailed === 'feed'){
                            countries = item.mcr
                            let _ingest = this.ingestionValues(item.houseNumber, countries, entities.ingestions)
                            visible = _.includes(v, _ingest);
                            //console.log(item, v, k, 'item v k field', field, _ingest, _.includes(v, _ingest))
                        }
                        if (item.elementTypeDetailed === 'production'){
                            countries = entities.channel.getItem(item.channel)
                            countries = countries.country
                            let _ingest = this.ingestionValues(item.houseNumber, countries, entities.ingestions)
                            visible = _.includes(v, _ingest);
                        }
                        if (item.elementTypeDetailed === 'contribution') {
                            let _ingest = this.ingestionValues(item.houseNumber, item.country, entities.ingestions)
                            visible = _.includes(v, _ingest);
                        }

                        if (['task', 'mcrComment'].indexOf(item.elementTypeDetailed) > -1) {
                            visible = true
                        }

                        // finished filter
                        if (_.includes(v, 'finished')) {
                            visible = false
                            let _mcrStatus = item.mcrStatus ? item.mcrStatus : (item.mcrstatus ? item.mcrstatus : null)
                            Lazy(_mcrStatus).each((v1,k1) => {
                                // country also should be the same darling.
                                visible = v1.status === 4 && v1.country === app.$data.user.get.country;
                            })
                        }
                       
                        break

                    // Special condition for "sport" that isn't present inside an item.
                    case (!item.hasOwnProperty(k) && field === 'sport' && item.league && entities.hasOwnProperty('league') && entities.hasOwnProperty('sport')):
                        const league = entities.league.getItem(item.league)
                        const sport = entities.sport.getItem(league.reference)
                        visible = (!league && v === '_empty_') || (v.indexOf(sport.id) > -1) || (['task', 'mcrComment'].indexOf(item.elementTypeDetailed) > -1)
                        break
                    //case (((['contribution'].indexOf(item.elementType) > -1) || isContAssocExists) && (field == 'country')):
                    case (((['contribution'].indexOf(item.elementTypeDetailed) > -1)) && (field === 'country')):
                        visible = v.indexOf(item.country) > -1;
                        break
                    //case ( (['contribution'].indexOf(item.elementType) > -1 || isContAssocExists) && (field == 'contribution') ):
                    case ( (['contribution'].indexOf(item.elementTypeDetailed) > -1) && (field == 'contribution') ):
                        visible = v.indexOf(item.info) > -1;
                        break
                    // Special condition for "country" on productions. (Channel of Interest logic implemented also)
                    case ( (['production'].indexOf(item.elementTypeDetailed) > -1) && (field === 'country') ):
                        _uCount = app.$data.user.get.country
                        //Here we suppose eng, edit and car should be filtered by equipment type country
                        if (item.productionType === "eng" || item.productionType === "edit" || item.productionType === "car" || item.runType === 89 || item.runType === 90){
                            //let equipments = item.equipment.filter(t => _.includes(v,t.country));
                            let equipments = item.equipment.filter(t => {
                                let interEq = _.intersection(v,t.destination)
                                return interEq && interEq.length > 0
                            });
                            visible = !!(equipments && equipments.length > 0)
                        }else{
                            const channel = entities.channel.getItem(item.channel)
                            // channel description for the channel ownership wN

                            if (typeof channel === 'undefined' || !channel) {
                                console.error("CHANNEL NOT FOUND AT 851! item -> ", item)
                                visible = true
                                return true;
                            }

                            let _interest
                            if (channel.description) _interest = channel.description.split(',')

                            // equipment usage view, doesnt care about channel interest.
                            let equipmentCount = item.equipment.filter(t => {
                                let intersec = _.intersection(v,t.destination)
                                return intersec.length > 0
                            }).length;
                            visible = (!item.channel && v === '_empty_') || (!!(channel && v.indexOf(channel.country) > -1)) || _.includes(_interest, _uCount) || equipmentCount > 0
                            
                        }
                        break
                    case ( (['task'].indexOf(item.elementTypeDetailed) > -1) && (field === 'country') && entities.hasOwnProperty('activityTypes')):
                        _uCount = app.$data.user.get.country
                        const activityType = entities.activityTypes.getItem(item.runType)
                        let currentCountries = activityType.country ? activityType.country : defaultCountries
                        if(Array.isArray(currentCountries)) {
                            visible = true
                        }
                        else {
                            visible = _.includes(v, currentCountries)
                        }
                        break
                    case (['feed'].indexOf(item.elementTypeDetailed) > -1) && (field === 'mcr') :
                        _uCount = app.$data.user.get.country
                        visible =  _.intersection(v, item.mcr).length > 0
                        
                        break
                    case ( (['production', 'feed'].indexOf(item.elementTypeDetailed) > -1) && (field == 'contribution') ):
                        visible = true;
                        break
                    case ( (['contribution', 'feed'].indexOf(item.elementTypeDetailed) > -1) && (field == 'channel') ):
                        visible = true;
                        break
                    case (Array.isArray(item[field])):
                        //TODO: check for associations and contributions here

                        // The filter value is an array and so the item value,
                        // then we need to find any of the filter values inside
                        // the item value array.
                        if (Array.isArray(v)) {
                            visible = v.findIndex(z => item[field].indexOf(z) > -1) > -1
                        }

                        // The filter value isn't an array.
                        else {
                            visible = item[field].indexOf(v) > -1
                        }

                        break
                    case (typeof v === 'object' && !Array.isArray(v)):
                        //TODO: check for associations and contributions here
                        //console.log("not handled");
                        switch (v.type) {
                            case 'text':
                                visible = !!Lazy(item)
                                    // types are already string and lowercase whats the meaning for re-filter??
                                    .filter((itemValue, itemField) => {
                                        return typeof itemValue === 'string' 
                                        || itemField.toLowerCase() === 'id' 
                                        || itemField.toLowerCase() === 'crew'
                                    })
                                    .find((itemValue, itemField) => {
                                        if (itemField.toLowerCase() === 'id'){
                                            itemValue = itemValue + '';
                                            return itemValue.toLowerCase().indexOf(v.value.toLowerCase()) > -1
                                        }else if (itemField.toLowerCase() === 'crew'){
                                            if (Array.isArray(itemValue) && itemValue.length > 0){
                                                let crew = itemValue.find(t => {
                                                    if (t.user && t.user.name){
                                                        return t.user.name.toLowerCase().indexOf(v.value.toLowerCase()) > -1
                                                    }
                                                })
                                                return crew;
                                            }
                                        }else{
                                            return itemValue.toLowerCase().indexOf(v.value.toLowerCase()) > -1
                                        }
                                        
                                    })
                                    
                                break
                        }
                        break


                    // The item value is not an array.
                    default:

                        // The filter value is an array, then we try to find the
                        // item value (item[k]) inside that array.
                        if (Array.isArray(v)) {
                            visible = (!item.hasOwnProperty(field) && v == '_empty_') || v.indexOf(item[field]) > -1 ? true : false
                        }

                        // A simple comparison can be done between the values.
                        else {
                            visible = (!item.hasOwnProperty(field) && v == '_empty_') || (item[field] == v) ? true : false
                        }

                        break
                }
            })
        //console.log('whats the last', visible)
        return visible

    }

}

export default Item
